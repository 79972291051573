.rdr-buttons-position{
    bottom: 1rem;
}

.rdrDefinedRangesWrapper{
    display: block;
    float: left;
  }

  .rdrDateRangeWrapper{

    display: block;
    float: left;

  }

  .rdrDateRangePickerWrapper {
    display: block !important;


  }

  .buttonblockfloat
  {
    display: block !important;
    float: left;

  }