body{
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  width: auto;
  padding-left: 5px;
  padding-right: 5px;
  height: 100%;
  overflow-y: hidden;

}


.btn
{
  margin: 10px;
}

.button2
{
  max-width: 100px;
  width: 100px;
  height: 35px;
  max-height: 35px;
}

.viewport {
  position: absolute;
  top: 70px;
  
  border: 10px solid red;

}

video
{
  width: 350px;
  height: 400px;
  transform: scaleX(1) scaleY(1) 
}

.drawingBuffer{
  position: absolute;
  top: 0px;
  left: 10px;
  visibility: hidden;
}


.belowbuf{
  position: absolute;
  width: 95%;
  top: 550px;

}

h1 {
text-align: center;
width: 100%;

}

h2 {
  text-align: center;
  
  }

  .BodyNav
  {
    border: 1px solid black;
    overflow-y: auto;
    max-height: 80vh;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 50px;
  }


  .navbutton{
  width: 40px;
  height: 40px;
  }

  .has-error
  {
    border: 1px solid red;
  }

  .ErrMsg
  {
    font-style: normal;
    color: black;
    background: red;
    font-weight: bold;
  }

  .okmsg .modal-content
  {
    background-color: green;
    color: white;
  }

  .modal-title
  {
 
    text-align: center;
    font-size: 2rem;
  }






  	/* Force table to not be like tables anymore */
	.transposetable table, .transposetable thead, .transposetable tbody, .transposetable th, .transposetable td, .transposetable tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	.transposetable thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	.transposetable tr { border: 1px solid #ccc; }
	
	.transposetable td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	.transposetable td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
  }
  
  .transposetable td:nth-of-type(1):before { content: "Date"; }
  .transposetable td:nth-of-type(2):before { content: "ECR id"; }
  .transposetable td:nth-of-type(3):before { content: "PLU Value"; }
  .transposetable td:nth-of-type(4):before { content: "PLU Count"; }
  .transposetable td:nth-of-type(5):before { content: "Gross Sales"; }
  .transposetable td:nth-of-type(6):before { content: "Cash Sales"; }
  .transposetable td:nth-of-type(7):before { content: "Card Sales"; }
  .transposetable td:nth-of-type(8):before { content: "Discount 1"; }
  .transposetable td:nth-of-type(9):before { content: "Discount 3"; }
  .transposetable td:nth-of-type(10):before { content: "Net Sales"; }
  .transposetable td:nth-of-type(11):before { content: "Non Tax Sales"; }
  .transposetable td:nth-of-type(12):before { content: "Vat 1 Sales"; }
  .transposetable td:nth-of-type(13):before { content: "Vat 2 Sales"; }
  .transposetable td:nth-of-type(14):before { content: "Vat 1"; }
  .transposetable td:nth-of-type(15):before { content: "Vat 2"; }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  
  .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
  }

  .Error
  {
    background-color: red;
  }


  
  thead {

    position: sticky;
    top: -1px;
    background-color: gray;
  }

  .table{
    width: auto;

  }

  .AA
  {
    display: block;
    width: 100%;

  }

  .AB
  {
    display: block;
    float: left;
   
  }

  .AC
  {
    display: block;
    width: 80%;
    float: left;
  }

  .active
  {
    background: green !important;
  }

  input[readonly]{
    background-color: #bbb;
    border: 0;
    font-size: 1em;
  }

  @media screen and (max-width: 800px) {

    .btn-block {
      display: block;
      width: 100px;
      height: 100px;
     
  }

  }